@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
.App {
  text-align: center;
  font-size: 20px;
  line-height: 2em;
  color: #000;
  font-family: 'Roboto Slab', serif;
}

.App-header {
  background-image: url(/static/media/iceland.ce4d7bd9.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  margin-top: -46px;
}

.App-header .down-arrow {
  cursor: pointer;
  position: absolute;
  bottom: 5%;
}

.no-wrap {
  white-space: nowrap;
}

.App-header .contents {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 1s;
}

.App-header hr {
  border-top: 2px solid #fff;
  margin-top: 1em;
  width:30%;
}

.App-header .myPhoto {
  width:125px;
}

.App-header .main-text {
  font-size: 2.25em;
  font-weight: 600;
}

.App-header .sub-text {
  font-size: 1.5em;
  margin-bottom: 0.25em;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */

header,
section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

section {
  padding: 2em;
}

section .contents {
  min-width: 275px;
  max-width: 800px;
}

section p {
  margin-bottom: 1em;
}

section hr {
  border-top: 2px solid #000;
  width:150px;
  margin: 25px auto;
}

section h2 {
  font-size: 2em;
}

.what-i-do {
  color: #fff;
  background-image: url(/static/media/banner_background.4df3ebcb.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.what-i-do hr {
  border-color: #fff;
}

footer {
  background: grey;
  color: white;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
}
footer a {
  color: white;
}
  
@media (min-width: 1600px){
  .App {
    font-size: 30px;
  } 
}
.contact li {
    padding: 10px;
    display: inline-block;
    opacity: 0.6;
    position: relative;
    top: 0;
}

.contact li a {
    color: inherit;
    text-decoration: none;
}

.contact li:hover {
    opacity: 1;
    top: -10px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 1024px){
    .contact li {
        opacity: 1;
    }
}
.brand {
    height: 36px;
    vertical-align: middle;
}
.nav-item a {
    display: block;
    padding: .25rem 1.5rem;
}

.nav-item {
    color: #000;
    background-color: inherit;
    cursor: pointer;
}

.nav-item:hover {
    color: #FFF;
    background-color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
