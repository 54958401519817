.brand {
    height: 36px;
    vertical-align: middle;
}
.nav-item a {
    display: block;
    padding: .25rem 1.5rem;
}

.nav-item {
    color: #000;
    background-color: inherit;
    cursor: pointer;
}

.nav-item:hover {
    color: #FFF;
    background-color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}