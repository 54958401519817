@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300&display=swap');
.App {
  text-align: center;
  font-size: 20px;
  line-height: 2em;
  color: #000;
  font-family: 'Roboto Slab', serif;
}

.App-header {
  background-image: url('./images/iceland.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  margin-top: -46px;
}

.App-header .down-arrow {
  cursor: pointer;
  position: absolute;
  bottom: 5%;
}

.no-wrap {
  white-space: nowrap;
}

.App-header .contents {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.App-header hr {
  border-top: 2px solid #fff;
  margin-top: 1em;
  width:30%;
}

.App-header .myPhoto {
  width:125px;
}

.App-header .main-text {
  font-size: 2.25em;
  font-weight: 600;
}

.App-header .sub-text {
  font-size: 1.5em;
  margin-bottom: 0.25em;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

header,
section {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

section {
  padding: 2em;
}

section .contents {
  min-width: 275px;
  max-width: 800px;
}

section p {
  margin-bottom: 1em;
}

section hr {
  border-top: 2px solid #000;
  width:150px;
  margin: 25px auto;
}

section h2 {
  font-size: 2em;
}

.what-i-do {
  color: #fff;
  background-image: url('./images/banner_background.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

.what-i-do hr {
  border-color: #fff;
}

footer {
  background: grey;
  color: white;
  padding: 10px;
  font-size: 14px;
  line-height: 20px;
}
footer a {
  color: white;
}
  
@media (min-width: 1600px){
  .App {
    font-size: 30px;
  } 
}