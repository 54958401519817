.contact li {
    padding: 10px;
    display: inline-block;
    opacity: 0.6;
    position: relative;
    top: 0;
}

.contact li a {
    color: inherit;
    text-decoration: none;
}

.contact li:hover {
    opacity: 1;
    top: -10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 1024px){
    .contact li {
        opacity: 1;
    }
}